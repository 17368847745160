import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { Link } from "gatsby";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Guia d'Accés per a Policies Locals de Catalunya</Title>
                <Text>
                    <p className="mb-5">
                        Una de les dificultats presents per qui desitgi accedir a qualsevol Policia
                        Local catalana és l'absència d'un temari comú que l'ajudi a preparar-se
                        l'oposició.
                    </p>

                    <p className="mb-5">
                        Tenint en compte aquesta circumstância hem creat aquest libre, que recull
                        els temes més sol licitats a les diverses oposicions de Policia Local de
                        Catalunya, amb l'objectiu de facilitar-vos-en l'accés. Triar les eines per
                        preparar-se una oposició és una opció personal.
                    </p>

                    <p>
                        Nosaltres, des de l'experiencia i des de l'aplicació de les tècniques
                        d'estudi més eficaces, hem creat una bona eina, i la volem compartir amb tu.
                    </p>
                </Text>
            </div>

            {/** Preview dle libro */}
            <div className="container lg:px-0 py-20">
                <Title>Preview del llibre</Title>
                <Text className="text-justify">
                    A continuació us adjuntem un resum en format pdf del contingut del llibre.
                </Text>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/318/promo.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    />
                </div>
            </div>

            <div className="container lg:px-0 py-20">
                <Title>Preview del llibre</Title>
                <Text className="text-justify">
                    A continuació us adjuntem un resum en format pdf del contingut del curs.
                </Text>

                <p className="mt-5">
                    <a
                        rel="noopener noreferrer"
                        href={require("@static/documents/products/318/preview.pdf")}
                        target="_blank"
                        className="default-link"
                    >
                        Clic per descarregar el pdf
                    </a>
                    .
                </p>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/246/preview.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
